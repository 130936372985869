import React, { useState } from 'react';
import ProductDetailsModal from './ProductDetailsModal';
import { Tilt } from 'react-tilt'; // Import the Tilt component

const ProductCard = ({ product }) => {
  const { image, description, subcategory } = product;

  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const handleDetailsClick = () => {
    setShowDetailsModal(true);
  };

  const closeDetailsModal = () => {
    setShowDetailsModal(false);
  };

  return (
    <Tilt options={{ max: 25, scale: 1.05 }}>
    <div className="bg-white shadow-md rounded-lg w-64 h-96 m-4 flex flex-col">
      <img src={image} alt={subcategory} className="w-64 h-48 object-cover rounded-t-lg" />
      <div className="p-4 flex-grow">
        <h2 className="text-xl font-bold mb-2" style={{ color: '#229BFF' }}>{subcategory}</h2>
        <p className="text-lg font-bold text-black flex-grow">{description}</p>
      </div>
      <button
        className="bg-primary text-white px-4 py-2 rounded-b-lg hover:bg-secondary"
        style={{ borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem', backgroundColor: '#5271FF' }}
        onClick={handleDetailsClick}
      >
        
Details Bekijken
      </button>
        {showDetailsModal && (
          <ProductDetailsModal product={product} onRequestClose={closeDetailsModal} />
        )}
      </div>
    </Tilt>
  );
};

export default ProductCard;
