import { createRoot } from 'react-dom/client'; 
import React from 'react';
import App from './App';
import './index.css';


createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if (import.meta.env.MODE === 'development') {
  import('react-refresh/runtime').then((RefreshRuntime) => {
    RefreshRuntime.injectIntoGlobalHook(window);
    window.$RefreshReg$ = () => {};
    window.$RefreshSig$ = () => (type) => type;
  }).catch((error) => {
    console.error('Failed to inject Refresh runtime:', error);
  });
}
