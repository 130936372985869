import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { motion } from "framer-motion"; // Import motion from framer-motion
import { slideIn } from "../utils/motion";

export default function MyCarousel({
  slides,
  autoSlide = false,
  autoSlideInterval = 3000,
}) {
  const [curr, setCurr] = useState(0);

  const prev = () => setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  const next = () => setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, []);

  if (!slides || slides.length === 0) {
    return null;
  }

  return (
    <motion.div
      className="overflow-hidden relative"
      initial={{ opacity: 0 }} // Initial animation state
      animate={{ opacity: 1 }} // Animation state when component appears
      exit={{ opacity: 0 }} // Animation state when component disappears
    >
      <motion.div
        className="flex transition-transform ease-out duration-500"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {slides}
      </motion.div>
      <div className="absolute inset-0 flex items-center justify-between p-4">
        <button
          onClick={prev}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover-bg-white"
        >
          <ChevronLeft size={40} />
        </button>
        <button
          onClick={next}
          className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover-bg-white"
        >
          <ChevronRight size={40} />
        </button>
      </div>

      <div className="absolute bottom-4 right-0 left-0">
        <motion.div
          initial={{ opacity: 0 }} // Initial animation state
          animate={{ opacity: 1 }} // Animation state when component appears
          exit={{ opacity: 0 }} // Animation state when component disappears
          className="flex items-center justify-center gap-2"
        >
          {slides.map((_, i) => (
            <motion.div
              key={i}
              initial={{ scale: 0.5, opacity: 0 }} // Initial animation state
              animate={{ scale: 1, opacity: 1 }} // Animation state when component appears
              exit={{ scale: 0.5, opacity: 0 }} // Animation state when component disappears
              className={`
              transition-all w-3 h-3 bg-white rounded-full
              ${curr === i ? "p-2" : "bg-opacity-50"}
            `}
            />
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
}
