import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoThumbnail from "./VideoThumbnail";

const VideoCarousel = ({ videoThumbnails }) => {
  const sliderRef = useRef(null);

  const handleThumbnailClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const slidesToShow = videoThumbnails ? videoThumbnails.length : 0;

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
  };

  return (
    <div
      className="video-carousel"
      style={{ overflowY: "scroll", maxHeight: "500px" }} // Adjust maxHeight as needed
      onMouseEnter={() => {
        sliderRef.current.innerSlider.pause("paused");
      }}
      onMouseLeave={() => {
        sliderRef.current.innerSlider.autoPlay("play");
      }}
    >
      <Slider {...settings} ref={sliderRef}>
        {videoThumbnails && videoThumbnails.map((video, index) => (
          <div key={index} className="carousel-item" onClick={() => handleThumbnailClick(index)}>
            <VideoThumbnail {...video} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default VideoCarousel;
