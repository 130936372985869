import {
  mobile,
  backend,
  creator,
  web,
  javascript,
  typescript,
  html,
  css,
  reactjs,
  redux,
  tailwind,
  nodejs,
  mongodb,
  git,
  figma,
  docker,
 
  carrent,
  jobit,
  tripguide,
  threejs,
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "Over",
  },
  {
    id: "ourmission",
    title: "Onze missie",
  },
  {
    id: "products-gallery",
    title: "Producten",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "Fair Trade",
    icon: web,
  },
  {
    title: "Hemp",
    icon: mobile,
  },
  {
    title: "Tea & Coffee",
    icon: backend,
  },
  {
    title: "Jewelry",
    icon: creator,
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "Three JS",
    icon: threejs,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "figma",
    icon: figma,
  },
  {
    name: "docker",
    icon: docker,
  },
];


const projects = [
  {
    name: "Economie",
    description:
   "Afgelegen gebieden in ontwikkelingslanden als Nepal zijn voor een groot deel zelfvoorzienend. Dat is aan de ene kant positief, maar er zijn ook nadelen. Er is geen kapitaal, dus er zijn geen reserves aanwezig. Met de verkoop van producten die in afgelegen gebieden gemaakt zijn of waarvan de grondstoffen uit afgelegen gebieden komen zal de plaatselijke economie gestimuleerd worden.",
    tags: [
      {
        name: "farmers",
        color: "blue-text-gradient",
      },
      {
        name: "workers",
        color: "green-text-gradient",
      },
      {
        name: "living standard",
        color: "pink-text-gradient",
      },
    ],
    image: carrent,
    source_code_link: "https://github.com/",
  },
  {
    name: "Gezondheidszorg en onderwijs",
    description:
      "Gezondheidszorg en onderwijs vergen kapitaal. De door staat aangeboden gezondheidszorg en onderwijs zijn vaak onvoldoende. Met extra geld in de lokale economie kunnen mensen vaker de juiste gezondheidszorg en een beter onderwijs voor henzelf en hun kinderen bereiken.",
    tags: [
      {
        name: "health",
        color: "blue-text-gradient",
      },
      {
        name: "education",
        color: "green-text-gradient",
      },
      {
        name: "future",
        color: "pink-text-gradient",
      },
    ],
    image: jobit,
    source_code_link: "https://github.com/",
  },
  {
    name: "Voedsel",
    description:
      "Zelfvoorzienendheid is mooi, maar in geval van een mislukte oogst of andere tegenslagen kan het  leiden tot voedselonzekerheid. Er zijn geen reserves of kapitaal voorhanden om voedsel aan te scha%en. Door andere manieren van inkomen waarbij geld beschikbaar komt kunnen meer gezinnen voedselzekerheid bereiken.",
    tags: [
      {
        name: "nutrition",
        color: "blue-text-gradient",
      },
      {
        name: "malnutrition",
        color: "green-text-gradient",
      },
      {
        name: "healthy diet",
        color: "pink-text-gradient",
      },
    ],
    image: tripguide,
    source_code_link: "https://github.com/",
  },
];

export { services, technologies,projects };
