import React, { useEffect } from 'react';

const CrispChat = () => {
  useEffect(() => {
    // Replace 'your-crisp-website-id' with your actual Crisp website ID
    const websiteId = 'c7715d10-51e5-461e-8f44-ce44b028572c';

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = websiteId;

    const script = document.createElement('script');
    script.src = 'https://client.crisp.chat/l.js';
    script.async = 1;

    document.getElementsByTagName('head')[0].appendChild(script);

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.getElementsByTagName('head')[0].removeChild(script);
    };
  }, []);

  return <div></div>;
};

export default CrispChat;
