// VideoThumbnail.jsx
import React from "react";

const VideoThumbnail = ({ title, description, videoUrl }) => {
  const handleTitleClick = () => {
    window.open(videoUrl, "_blank"); // Open the YouTube link in a new tab
  };

  return (
    <div className="cursor-pointer p-4 rounded-md shadow-md hover:bg-blue-900 hover:text-white focus:outline-none focus:border-black">
      <div className="flex items-center mb-8">
        <div className="w-2/3">
          <iframe
            title={title}
            width="100%"
            height="auto"
            src={videoUrl}
            frameBorder="0"
            allowFullScreen
            className="rounded-md"
          ></iframe>
        </div>
        <div className="w-1/3 ml-4">
          <div onClick={handleTitleClick} className="text-lg font-bold mb-2 cursor-pointer">{title}</div> {/* Make the title clickable */}
          <p className="text-sm">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default VideoThumbnail;