import React, { useEffect } from 'react';

const GoogleTranslate = () => {
  useEffect(() => {
    const delayTranslationInit = setTimeout(() => {
      const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'nl', // Dutch as the default language
            includedLanguages: 'nl,en,ms,ta,zh-CN,es,fr,de,it,ja,ko,pt,ru,af,sq,am,ar,hy,az,eu,be,bn,bs,bg,my,ca,ceb,ny,zh-TW,co,hr,cs,da,nl,en,eo,et,tl,fi,fy,gl,ka,de,el,gu,ht,ha,haw,iw,hi,hmn,hu,is,ig,id,ga,it,ja,jw,kn,kk,km,ko,kur,ky,lo,la,lv,lt,lb,mk,mg,ms,ml,mt,mi,mr,mn,my,ne,no,or,ps,fa,pl,pt,pa,ro,ru,sm,gd,sr,st,sn,sd,si,sk,sl,so,es,su,sw,sv,tg,ta,te,th,tr,uk,ur,ug,uz,vi,cy,xh,yi,yo,zu', // Adding more languages
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          },
          'google_translate_element'
        );
      };

      const addScript = document.createElement('script');
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;

      // Remove Google Translate 
      const googleTranslateElement = document.getElementById('google_translate_element');
      if (googleTranslateElement) {
        googleTranslateElement.remove();
      }
    }, 2000); // Adjust the delay time 

    return () => clearTimeout(delayTranslationInit);
  }, []);

  return null; // Google Translate component doesn't render anything visible
};

export default GoogleTranslate;
