import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const VerifyEmail = () => {
  const { uid, token } = useParams(); // Assuming you are using React Router for routing

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`/api/customers/verify-email/${uid}/${token}/`);
        console.log("Verification response:", response.data);
        // Handle successful verification (e.g., show success message)
      } catch (error) {
        console.error("Verification error:", error);
        // Handle error (e.g., show error message)
      }
    };

    verifyEmail();
  }, [uid, token]);

  return (
    <div>
      
      {/* You can add loading indicators or messages here */}
    </div>
  );
};

export default VerifyEmail;
