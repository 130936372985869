import { Suspense } from 'react';
import React from 'react';
import { motion } from 'framer-motion';

import MyCarousel from "./Carousel";
import { styles } from "../styles";
import { SectionWrapper } from "../hoc";
import { fadeIn, textVariant, slideIn } from "../utils/motion";
import VideoCarousel from "./VideoCarousel"; // Import the VideoCarousel component

const About = () => {
  const carouselSlides = [
    <img key="slide1" src="src/assets/chi.jpg" alt="Image 1" />,
    <img key="slide2" src="src/assets/monochrome.jpg" alt="Image 2" />,
    <img key="slide3" src="src/assets/w.jpeg" alt="Image 3" />,
    <img key="slide4" src="src/assets/b.jpg" alt="Image 4" />,
  ];

  const videoThumbnails = [
    {
      title: "Manavta (Fair for All)",
      description: " Promovideo van Fair Trade-producten, Manavta",
      videoUrl: "https://www.youtube.com/embed/bz0UQI3VHgA",
    },
    {
      title: "Manushi Nepal",
      description: "Ontdek ons Fair Trade handwerk uit Nepal",
      videoUrl: "https://www.youtube.com/embed/zzydyUa1iRA",
    },
    {
      title: "Leaf Plus Nepal",
      description: "Hoe worden de bladproducten geproduceerd?",
      videoUrl: "https://www.youtube.com/embed/bJw2kI9Q9hg",
    },
    {
      title: "Hemp Fabric Production",
      description: "Op weg naar duurzame kleding",
      videoUrl: "https://www.youtube.com/embed/fx9Z7powWPg",
    },
    {
      title: "Ecofriendly Leaf Products",
      description: "Sociale, milieuvriendelijke en biologisch afbreekbare bladproducten",
      videoUrl: "https://www.youtube.com/embed/m6VA7pCiyu0",
    },
    {
      title: "VOORDELEN VAN HENNEPZADEN",
      description: "Gezondheidsvoordelen van hennepzaadolie voor haar en huid",
      videoUrl: "https://www.youtube.com/embed/HXtUuWUhWLs",
    },
    {
      title: " Mananag Vallei Premium Wijn",
      description: "UNCORKING PARADISE: DE VERBORGEN PAREL VAN MANANG VALLEY ONTHULD",
      videoUrl: "https://www.youtube.com/embed/TlM72Xt_r9E",
    },
  ];

  return (
    <>
      <motion.div variants={textVariant()}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          <div className="sm:col-span-1">
            <motion.div variants={slideIn()}>
              <p className="font-normal text-lg text-secondary">Introductie</p>
              <h2 className="font-bold text-3xl text-white">Overzicht.</h2>
              <motion.p
                variants={fadeIn("", "", 0.1, 1)}
                className={`mt-4 ${styles.sectionSubText} text-secondary max-w-3xl leading-[30px]`}
              >
                Ramro Imports B.V. importeert producten van o.a. hennep, katoen en vilt uit Nepal. Voor consumenten verkopen wij via fairtrade en fairmade producten onder onze merknaam Manavta op Manavta.nl. Voor groothandel kunt u hier terecht. 
                <br /><br />
                Ramro betekent goed in Nepalees. Wij bieden dan ook kwalitatief goede producten aan voor een eerlijke prijs.
                <br /><br />
                Nepal heeft prachtige kundige vakmensen. Veel producten worden hier nog op ambachtelijke wijze gemaakt. In andere delen van de wereld zou dat de producten voor veel mensen onbetaalbaar maken, maar in Nepal liggen de prijzen en kosten van levensonderhoud veel lager dan in Europa. Daardoor kunnen we veel handgemaakte producten voor een redelijke prijs aanbieden.
                <br /><br />
                Onze partner in Nepal en Fair Trade International zien erop toe dat fairtrade producten gemaakt worden volgens de fairtrade normen.
                <br /><br />
                Wij bieden producten aan particuliere en zakelijke klanten aan, een deel is op voorraad en een deel op bestelling. Neem contact met ons op via het contactformulier of telefoon.
              </motion.p>
            </motion.div>
          </div>

          <div className="sm:col-span-1">
            <motion.div variants={slideIn()}>
              <h3 className="font-bold text-3xl text-white">Verhalen</h3>
              <div className="mt-4 overflow-y-auto h-full">
                <Suspense fallback={<div>Loading...</div>}>
                  <VideoCarousel videoThumbnails={videoThumbnails} />
                </Suspense>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
        <div className="sm:col-span-1">
          <motion.div variants={slideIn()}>
            <Suspense fallback={<div>Loading...</div>}>
              <MyCarousel slides={carouselSlides} autoSlide autoSlideInterval={3000} />
            </Suspense>
          </motion.div>
        </div>

        <div className="sm:col-span-1">
          <motion.div variants={slideIn()}>
            <h3 className="font-bold text-3xl text-white">Sustainability</h3>
            <motion.p
              variants={fadeIn("", "", 0.1, 1)}
              className={`mt-4 ${styles.sectionSubText} text-secondary max-w-3xl leading-[30px]`}
            >
              Ramro Imports staat voor import van duurzame producten. In landen als Nepal verdwijnt de zorg voor het milieu onder economische druk regelmatig op de achtergrond. Een milieuvriendelijke productiewijze staat bij ons voorop.
              <br /><br />
              Producten van hennep, palmbladeren en papier zijn beter voor het milieu dan katoen, plastic en metalen. We willen bijdragen aan een planeet waarin grondstoffen hergebruikt worden en de natuur niet vervuild wordt door schadelijke stoffen.
              <br /><br />
              Nog niet al onze verpakkingen zijn plastic vrij, maar we werken er met onze producenten hard aan om dit in de nabije toekomst te realiseren. Een circulaire economie, de natuur laat ons zelf zien hoe het hoort.
            </motion.p>
            <motion.p
              variants={fadeIn("", "", 0.1, 1)}
              className={`mt-4 ${styles.sectionSubText} text-secondary max-w-3xl leading-[30px]`}
            >
              <span>"The greatest threat to our planet is the belief that someone else will save it." - Robert Swan</span>
            </motion.p>
          </motion.div>
        </div>
      </div>

      {/* New Manushi Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-10">
        <div className="sm:col-span-1 flex flex-col justify-center">
          <motion.div variants={slideIn()}>
            <h3 className="font-bold text-3xl text-white">Onze Partner</h3>
            <motion.p
              variants={fadeIn("", "", 0.1, 1)}
              className={`mt-4 ${styles.sectionSubText} text-secondary max-w-3xl leading-[30px]`}
            >
              Onze partner in Nepal voor fairtrade producten is Manushi. Zij waren de eerste fairtrade organisatie in Nepal en zijn inmiddels meer dan 25 jaar actief.
              Zij begeleiden kleine ondernemers in productie, financiering en coaching en checken of aan alle Fairtrade vereisten is voldaan. Manushi koopt de producten tegen een eerlijke prijs op van lokale kleinschalige ondernemers en verkoopt ze door als gecertificeerd fair-trade product. 
              <br /><br />
              Daarnaast heeft Manushi ook eigen werkplaatsen waar ze zelf producten maakt en werkgelegenheid biedt aan vrouwen uit achterstandsposities. 
              Ter gelegenheid van het 25 jarig jubileum heeft Manushi deze catalogus uitgebracht. Op aanvraag leveren wij de artikelen uit de catalogus.
              <br /><br />
              Een deel van de artikelen staat met prijzen genoemd in onze eigen catalogussen op deze site. Bel of mail ons als u interesse heeft in één van deze artikelen. 
              Indien prijzen nog niet op onze website genoemd staan vragen wij deze voor u na. Artikelen die wij in voorraad hebben en reeds verkopen staan op <a href="https://www.manavta.nl" className="underline text-secondary">www.manavta.nl</a>.
            </motion.p>
         {/* Catalog Card */}
<div className="mt-4 p-4 border border-secondary rounded-lg bg-gray-800 flex items-center justify-center">
  <a 
    href="/Catalogs/Manushicatalog.pdf" 
    target="_blank" 
    rel="noopener noreferrer"
    className="text-secondary bg-gray-900 border border-secondary rounded-lg px-6 py-3 font-semibold hover:bg-secondary hover:text-gray-900 transition duration-300"
  >
    Bekijk de Manushi Catalogus
  </a>
</div>

          
          </motion.div>
        </div>

        <div className="sm:col-span-1">
        <motion.div variants={slideIn()}>
        <img 
      src="src/assets/man.jpg" 
      alt="Manushi Logo" 
      className="w-90 h-auto rounded-lg transition-transform duration-300 ease-in-out hover:scale-105 mb-4"
    />
          </motion.div>
          <motion.div variants={slideIn()}>
          <img 
      src="src/assets/manavta.png" 
      alt="Manavta Logo" 
      className="w-100 h-auto rounded-lg"
    />
          </motion.div>
         
         
        </div>
      </div>
    </>
  );
};

export default SectionWrapper(About, "about");
