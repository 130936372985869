import React, { useState } from 'react';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import ReactImageMagnify from 'react-image-magnify';
import netherlandsFlag from '../assets/netherlands.png';
import ukFlag from '../assets/uk.png';
import germanyFlag from '../assets/germany.png';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    border: 'none',
    borderRadius: '8px',
  },
};

Modal.setAppElement('#root');

const ProductDetailsModal = ({ product, onRequestClose }) => {
  const [isZoomActivated, setIsZoomActivated] = useState(false);

  const handleViewCatalog = (url) => {
    window.open(url, '_blank');
  };

  const handleConnectContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
    onRequestClose();
  };

  return (
    <Modal isOpen={true} onRequestClose={onRequestClose} style={customStyles}>
      <motion.button
        onClick={onRequestClose}
        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 cursor-pointer"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        &#10006;
      </motion.button>
      <div className="w-full h-full flex">
        <div className="w-65 bg-gray-200">
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: product.subcategory,
                  isFluidWidth: true,
                  src: product.image,
                },
                largeImage: {
                  src: product.image,
                  width: 1200,
                  height: 1800,
                },
                imageClassName: 'w-full h-full object-cover',
                isActivatedOnTouch: true,
                enlargedImagePosition: 'over',
                enlargedImageContainerClassName: 'enlargedImageContainer',
                enlargedImageClassName: 'enlargedImage',
                isHintEnabled: true,
                shouldHideHintAfterFirstActivation: false,
                hintTextMouse: 'Long Touch to Zoom',
                hintTextTouch: 'Long Press to Zoom',
                isEnlargedImageActive: isZoomActivated,
                onClick: () => setIsZoomActivated(!isZoomActivated),
              }}
            />
          </div>
        </div>
        <div className="w-35 p-4 flex flex-col bg-white">
          <div className="product-info mb-4">
            <h2 className="text-2xl font-semibold" style={{ color: '#229BFF' }}>
              {product.subcategory}
            </h2>
            <p className="text-gray-600">{product.description}</p>
          </div>
          <div className="product-actions">
            <div className="mb-4 flex justify-around space-x-4">
              <motion.button
                className="bg-primary text-white px-4 py-2 rounded-md hover:bg-secondary flex items-center justify-center space-x-2"
                style={{ backgroundColor: '#229BFF' }}
                onClick={() => handleViewCatalog(product.catalogUrl)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <img src={netherlandsFlag} alt="Netherlands" className="inline w-6 h-6" />
                <span>Catalogus Bekijken</span>
              </motion.button>
              <motion.button
                className="bg-primary text-white px-4 py-2 rounded-md hover:bg-secondary flex items-center justify-center space-x-2"
                style={{ backgroundColor: '#229BFF' }}
                onClick={() => handleViewCatalog(product.catalogUrlEnglish)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <img src={ukFlag} alt="UK" className="inline w-6 h-6" />
                <span>View Catalog</span>
              </motion.button>
              <motion.button
                className="bg-primary text-white px-4 py-2 rounded-md hover:bg-secondary flex items-center justify-center space-x-2"
                style={{ backgroundColor: '#229BFF' }}
                onClick={() => handleViewCatalog(product.catalogUrlGerman)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <img src={germanyFlag} alt="Germany" className="inline w-6 h-6" />
                <span>Katalog Anzeigen</span>
              </motion.button>
            </div>
            <div>
              <motion.button
                className="bg-primary text-white px-4 py-2 rounded-md hover:bg-secondary w-full"
                style={{ backgroundColor: '#229BFF' }}
                onClick={handleConnectContact}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Neem contact met ons op
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductDetailsModal;
