import React, { useState } from "react";
import axios from 'axios';

const SignUp = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    try {
      console.log('Submitting Data:', {
        username: formData.username,
        email: formData.email,
        password: formData.password
      });
      const response = await axios.post('/api/customers/register/', {
        username: formData.username,
        email: formData.email,
        password: formData.password
      });
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response.data);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-center text-navy-blue">Sign Up</h2>
      <form className="space-y-4" onSubmit={handleSubmit}>
       
        <input
          type="email"
          name="email"
          placeholder="Email"
          className="block w-full p-2 border rounded"
          value={formData.email}
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          className="block w-full p-2 border rounded"
          value={formData.password}
          onChange={handleChange}
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          className="block w-full p-2 border rounded"
          value={formData.confirmPassword}
          onChange={handleChange}
        />
        <button type="submit" className="block w-full bg-blue-500 text-white p-2 rounded">
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default SignUp;
