import React from 'react';
import { Link } from 'react-router-dom';
import myLogo from '../assets/logos.png';
import { navLinks } from '../constants';


const Footer = () => {
  // Function to handle opening terms and conditions PDF in a new tab/window
  const openTermsAndConditions = () => {
    window.open('/Catalogs/TermsandConditions.pdf', '_blank');
  };

  return (
    <footer className="bg-blue-1000 text-white py-8">
      <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center">
        {/* Left side: Links to sections */}
        <div className="text-center lg:text-left mb-4 lg:mb-0 lg:mr-auto">
          <h4 className="text-xl font-semibold mb-2">Ramro Imports BV</h4>
          <ul className="list-none">
            {navLinks.map((nav) => (
              <li className="mb-2" key={nav.id}>
                {/* Updated Link component to use hashes */}
                <Link to={`/#${nav.id}`} className="text-blue-200 hover:text-white">
                   {nav.title}
                </Link>
              </li>
            ))}
            {/* Link to terms and conditions PDF */}
            <li className="mb-2">
              <button onClick={openTermsAndConditions} className="text-blue-200 hover:text-white">
                Algemene Voorwaarden
              </button>
            </li>
          </ul>
        </div>

        {/* Centered Company Logo */}
        <div className="flex justify-center mb-4 lg:mb-0">
          <img src={myLogo} alt="Company Logo" className="w-40 h-40 lg:w-40 lg:h-40" />
        </div>

        {/* Right side: Company details */}
        <div className="text-center lg:text-right lg:ml-auto">
          {/* Company details */}
          <div className="text-base text-blue-300">
            <p className="mb-1">Ramro Imports</p>
            <p className="mb-1">Box A5315, Oder 20, 2491 DC </p>
            <p className="mb-1">
              Den Haag, Netherlands{' '}
              <a
                href="https://www.google.com/maps/dir/51.4492,6.6661/ramro+imports+bv/@51.6999781,2.8991771,7z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x47c5b188f435d91b:0x40766f0cdcf85294!2m2!1d4.2466094!2d52.0494106?entry=ttu"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-white"
              >
                Bekijk op kaart
              </a>
            </p>
            <p className="mb-1">Phone: +31617844159</p>
            <p className="mb-1">
              Email:{' '}
              <a href="mailto:info@example.com" className="text-gray-300 hover:text-white">
                info@ramroimports.nl
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
